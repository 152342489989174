<template>

		<div class="catout">

<div :class="cat + ' item'" id="item">
		<component :is="currentComponent" :item="item" :key="$route.fullPath" />
	</div>

</div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import LeftCategories from '@/components/left-categories.vue';
// import VueEasyLightbox from 'vue-easy-lightbox';

// import licomponent from '../components/licomponent.vue';
// import gallery from '../components/itemgallery.vue';

// import ErrorComponent from './components/ErrorComponent.vue';
// import LoadingComponent from './components/LoadingComponent.vue';

//import itempeople from '../components/item-people.vue';
// import itemgallery from '../components/itemgallery.vue';

// import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

// console.log('licomponent', licomponent);

export default {
	components: {LeftCategories},
	name: 'item',

	props: {
		cat: String,
	},


	data() {
		return {
			slug: this.$route.params.slug,
			photoIndex: 0,
			item: null,
		};
	},

	computed: {
		currentComponent() {
			//			console.log(this.cat);

			// console.log('tu uitem',this.item)

			return defineAsyncComponent({
				// loader: () => import(`@/components/item-${this.cat}.vue`),
				loader: () => import(`@/components/item-all.vue`),
			});
		},
	},
	created() {



	},

	methods: {



	},

	beforeMount() {

		this.item = null;

		let currentItem = this.$dat.items[this.slug];

		console.log('currentItem',currentItem);

				if (currentItem.images) {

			let gs = currentItem.images.length;
			if (gs) {
				// jakies zdjecia som
				for (let g = 0; g < gs; g += 1) {
					if (currentItem.images[g].pic) {
						// ten już zrobiony
						continue;
					}
					if (!currentItem.images[g].src) {
						currentItem.images[g].pic = '/' + this.$conf.photopath + '/default-' + this.cat + '-images.jpg';
						currentItem.images[g].src = 'none';
					} else {
						currentItem.images[g].pic = '/' + this.$conf.photopath + '/' + currentItem.images[g].src + '.jpg';
						currentItem.images[g].src = '/' + this.$conf.photopath + '/' + currentItem.images[g].src + '.jpg';
					}
				}
			}
		}

		if (currentItem.gal) {
			let gs = currentItem.gal.length;
			if (gs) {
				// jakies zdjecia som
				for (let g = 0; g < gs; g += 1) {
					if (currentItem.gal[g].pic) {
						// ten już zrobiony
						continue;
					}
					if (!currentItem.gal[g].src) {
						currentItem.gal[g].pic = '/' + this.$conf.photopath + '/default-' + this.cat + '-gal.jpg';
						currentItem.gal[g].src = 'none';
					} else {
						currentItem.gal[g].pic = '/' + this.$conf.photopath + '/' + currentItem.gal[g].src + '.jpg';
						currentItem.gal[g].src = '/' + this.$conf.photopath + '/' + currentItem.gal[g].src + '.jpg';
					}
				}
			}
		}

		if (this.cat == 'person') {
			currentItem.name = currentItem.name
				.split(', ')
				.reverse()
				.join(' ');
		}

		this.item = currentItem;
	},


	mounted() {




		// console.log('categoru',JSON.stringify(this.cat));
		// console.log('MOUNTED', this.$store);
		// tu wysyła że teraz pobierz posty i...
		//this.categoryClass = this.cat;
		// if(!this.$store.state.categories[this.cat]){
		// this.$store.dispatch('getCategory',{"category":this.cat});
		// }
		// this.$store.dispatch('getCategory',{"category":this.cat});
		// console.log('slodt', JSON.stringify(this.$store.state.categories[this.cat]));
	},
};
</script>

<style lang="less">
/*
.category {
	h1 {
		padding-top: 20px;
		padding-bottom: 20px;
		margin-bottom: 20px;
		margin-top: 0;
		text-align: right;
		color: white;
		font-weight: bold;
		font-size: 3rem;
		position: relative;
		overflow: hidden;

		.name {
			position: relative;
		}

		&::before {
			content: '';
			width: 300px;
			height: 100%;

			display: block;
			position: absolute;
			right: -100px;
			top: 0;
			z-index: 0;
			mask-position: center;
			mask-image: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0) 0%,
					rgba(0, 0, 0, 1) 100%
				),
				url('../assets/mask.png');
			mask-size: cover;
		}
	}
}
*/
.catout {
	display: flex;
	gap: 20px;
}

.catmenu {
}

.category {
	flex-grow: 1;
}
</style>
